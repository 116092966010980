import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Product from "./product"

const Menu = () => {

    const [active, setActive] = useState("category-0");
    const data = useStaticQuery(menuQuery)

    const menuCategories = data.wp.menuList.ACFMenu.categories;

    return (
        <div className="wrap-menu" id="our-menu">
            <div className="container">
                <div className="row gx-0">
                    <div className="col-md-12">
                        <h2 className="text-center px-3 py-3 fw-bold mb-0">Our Menu</h2>
                    </div>
                    <div className="col-md-3">
                        <div className="p-3 wrap-menu-list">
                            <ul>
                                { menuCategories && menuCategories.map((category, i) => {
                                    return (
                                        <li key={`category-${i}`} onClick={ () => !category.subcategories && setActive(`category-${i}`) }  aria-hidden="true" className={ `${active === `category-${i}` ? "active" : ""}${category.subcategories ? "nohover" : ""} `}>{category.title}
                                            { category.subcategories &&  
                                                <ul>
                                                    { category.subcategories.map((subcategory, j) => {
                                                        return (
                                                            <li key={`subcategory-${i}${j}`} onClick={ () => setActive(`subcategory-${i}${j}`) } aria-hidden="true" className={ active === `subcategory-${i}${j}` ? "active" : "" }>{subcategory.title}</li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </li>
                                    )
                                }) }
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="wrap-menu-show">
                            { menuCategories && menuCategories.map((category, i) => {
                                const categoryBanners = category.images
                                const categoryProducts = category.products
                                const categorySubcategories = category.subcategories
                                if (categoryProducts?.length > 0){
                                    return (
                                        <div key={`show-${i}`}  className={ active === `category-${i}` ? "active" : "" }>
                                            <div id={`banner-${i}`} className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000" data-bs-pause="false">
                                                <div className="carousel-indicators">
                                                    { categoryBanners?.length > 1 && categoryBanners.map((banner, key) => (
                                                        <button type="button" key={`banner-indicator${key}`} data-bs-target={`#banner-${i}`} data-bs-slide-to={key} className={`${key === 0 ? 'active' : ''}`} aria-label="Slide 1"></button>
                                                    ))}
                                                </div>
                                                <div className="carousel-inner"> 
                                                    { categoryBanners && categoryBanners.map((banner, key) => (
                                                        <div className={`carousel-item wrap-image ${key === 0 ? 'active' : ''}`} key={`banner-items-${i}${key}`}>
                                                            <GatsbyImage image={banner.bannersImage?.localFile.childImageSharp.gatsbyImageData} className={`d-block w-100`} alt={category.title} />
                                                            <h3 className="mb-0">{category.title}</h3>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="wrap-products row">
                                                { categoryProducts && categoryProducts.map((product, i) => {
                                                    return (
                                                        <Product detail={product} key={`product-${i}`} />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return categorySubcategories && categorySubcategories.map((subcategory, j) => {
                                        const subcategoryBanners = subcategory.images
                                        const subcategoryProducts = subcategory.products
                                        return (
                                            <div key={`showsubcategory-${i}${j}`}  className={ active === `subcategory-${i}${j}` ? "active" : "" }>
                                                <div id={`subcat-banner-${i}${j}`} className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="5000" data-bs-pause="false">
                                                    <div className="carousel-indicators">
                                                        { subcategoryBanners?.length > 1 && subcategoryBanners.map((banner, key) => (
                                                            <button type="button" key={`subcat-banner-indicator${key}`} data-bs-target={`#subcat-banner-${i}${j}`} data-bs-slide-to={key} className={`${key === 0 ? 'active' : ''}`} aria-label="Slide 1"></button>
                                                        ))}
                                                    </div>
                                                    <div className="carousel-inner"> 
                                                        { subcategoryBanners && subcategoryBanners.map((banner, key) => (
                                                            <div className={`carousel-item wrap-image ${key === 0 ? 'active' : ''}`} key={`subcat-banner-items-${i}${key}`}>
                                                                <GatsbyImage image={banner.bannersImage?.localFile.childImageSharp.gatsbyImageData} className={`d-block w-100`} alt={subcategory.title} />
                                                                <h3 className="mb-0">{subcategory.title}</h3>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="wrap-products row">
                                                    { subcategoryProducts && subcategoryProducts.map((product, i) => {
                                                        return (
                                                            <Product detail={product} key={`product-subcategory-${i}${j}`} />
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            }) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Menu

export const menuQuery = graphql`
    query {
        wp {
            menuList {
                ACFMenu {
                    categories {
                        title
                        images {
                            bannersImage {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: FULL_WIDTH, quality: 100, blurredOptions: {})
                                    } 
                                }
                            }
                        }
                        products {
                            description
                            lead
                            price
                            title
                        }
                        subcategories {
                            title
                            images {
                                bannersImage {
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(layout: FULL_WIDTH, quality: 100, blurredOptions: {})
                                        }
                                    }
                                }
                            }
                            products {
                                title
                                price
                                lead
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`