import React from 'react'

const Product = ({ detail }) => {
    return (
        <div className="col-md-6">
            <div className="wrap-product">
                <div className="wrap-product__header">
                    <h4>{detail.title}</h4>
                    <span>{detail.price ? detail.price?.toFixed(2) : detail.lead}</span>
                </div>
                <div className="wrap-product__description">{detail.description}</div>
            </div>
        </div>
    )
}

export default Product