import React from 'react'
import "../../css/@wordpress/block-library/build-style/style.css"
import "../../css/@wordpress/block-library/build-style/theme.css"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutUs = () => {
    
    const {
        wpPage: {
            title,
            content,
            carousel
        }
    } = useStaticQuery(aboutUsQuery)
    
    return (
        <div className="wrap-about-us py-5" id="about-us">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 py-3">
                        <h2 className="text-center">{title}</h2>
                    </div>
                    <div className="col-md-12 pb-2">
                        {parse(content)}
                    </div>
                    { carousel && carousel.carouselImages.map((image, key) => {

                        return (
                            <div className="col-md-4 pb-4 pb-md-0" key={`welcome-${key+1}`}>
                                <GatsbyImage image={image.carouselImage.localFile.childImageSharp.gatsbyImageData} alt="" />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AboutUs

export const aboutUsQuery = graphql`
    query aboutUs {
        wpPage(id: {eq: "cG9zdDo0OA=="}) {
            title
            content
            carousel {
                carouselImages {
                    carouselImage {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                            }
                            publicURL
                        }
                    }
                }
            }
        }
    }
`