import React from "react"
import Layout from "../components/general/layout"
import Seo from "../components/general/seo"
import Banners from "../components/home/banners"
import AboutUs from "../components/home/aboutUs"
import Menu from "../components/menu/menu"
import { graphql } from "gatsby"

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const bannersHome = data.wp.banners.ACFBanners.bannershome

  return (
    <Layout title={siteTitle} isHomePage>
			<Seo title={"Home"} lang="en"/>
      <Banners banners={bannersHome} location={"home"} dots />
      <AboutUs />
      <Menu />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wp {
      banners {
        ACFBanners {
          bannershome {
            html
            link
            title
            textAlign
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
              }
            }
          }
        }
      }
    }
  }
`
